<template>
  <div class="cont">
    <img src="./assets/pfp.png" alt="Profile Picture" id="pfp">
    <Header text="Devin Conway" />
    <Body />
    <Socials />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Body from './components/Body.vue'
import Socials from './components/Socials.vue'

export default {
  name: 'App',
  components: {
    Header,
    Body,
    Socials,
  }
}
</script>

<style>

  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

  * {
    background-image: url('./assets/bg.jpg');
    background-size: 1920px;
  }

  :root {
    --bg-color: #242424;
    --text-color: #f7f7f7;
  }

  #pfp {
    width: 150px;

    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26.800px;

    border-radius: 100px;

  }

  .cont {
    background: var(--bg-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    border-radius: 15px;
    border: var(--text-color) solid;
  }

  .header {
    background: var(--bg-color);
    color: var(--text-color);

    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    text-align: center;

    margin-left: 25px;
  }

  .bodyCont {
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    text-align: center;
    
    margin-left: 50px;
    margin-right: 50px;
  }
  .bodyCont * {
    background: var(--bg-color);
  }

  .socialCont {
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    text-align: center;

    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 26.800px;
  }
  .socialCont , .btnCont, .social {
    background: var(--bg-color);
  }
  .social {
    margin-bottom: 0;
  }

  .btnCont {
    padding: 5px;
  }

</style>
