<template>
    <div class="socialCont">
        <h1 class="social">Here are my socials!</h1>
        <div class="btnCont">
            <Button text="GitHub" icon="fa-brands fa-github" link="https://github.com/TheConwayy" />
            <Button text="Discord" icon="fa-brands fa-discord" link="https://discord.com/users/384010503824867328" />
            <Button text="Twitter" icon="fa-brands fa-twitter" link="https://twitter.com/DevinConwayy" />
            <Button text="Instagram" icon="fa-brands fa-instagram" link="https://www.instagram.com/devinconwayy/" />
        </div>
    </div>
</template>

<script>
import Button from './Button.vue'

export default {
    name: 'Socials',
    components: {
        Button
    }
}
</script>