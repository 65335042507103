<template>
    <button class="btn" @click="onClick()">
        <i :class=icon></i> {{text}}
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: String,
        icon: String,
        link: String
    },
    methods: {
        onClick() {
            window.open(this.link, '_blank')
        }
    }
}
</script>

<style>

    .btn {
        background: #f6f6f6;
        border: none;
        border-radius: 8px;
        text-align: center;
        color: #242424;
        font-size: 20px;
        padding: 10px 15px;
        margin: 5px;
    }

    .btn:hover {
        cursor: pointer;
        transition: ease-in 0.3s;
        color: #f6f6f6;
        background: #242424;
        border: #f6f6f6 solid 1.5px;
    }
    .btn:hover * {
        cursor: pointer;
        transition: ease-in 0.3s;
        color: #f6f6f6;
        background: #242424;
    }

    .btn * {
        background: #f6f6f6;
    }

</style>