<template>
    <div class="bodyCont">
        <p>
            Hey there! My name is Devin Conway and I am a young developer that is still learning the ropes, but learns relatively easily and has a wild imagination.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Body',
    props: {
        status: String,
        color: String
    }
}
</script>