<template>
    <h1 class="header">{{text}}</h1>
</template>

<script>
export default {
    name: 'Header',
    props: {
        text: String
    }
}
</script>